<template>
  <div class="page">
    <a class="bluelink" href="https://www.etsy.com/uk/shop/littlegardensoapery"><h3>Click below to browse <span class="greenlink">the little garden soapery</span> store on Etsy.</h3></a>
    <a href="https://www.etsy.com/uk/shop/littlegardensoapery"><img class="corp-logo" src="../assets/Etsy_logo.png" alt="the little garden soapery etsy store page"/></a>
  </div>
</template>
<style>


</style>


