import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import StoreView from '../views/StoreView.vue'
import BlogView from '../views/BlogView.vue'
import ContactView from '../views/ContactView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'the little garden soapery | handmade soaps and thoughtful gifts. ',
      description: 'Hand crafted soaps made with olive oil, coconut oil and shea butter. No palm oil, SLS or parabens here! Made in small batches using the cold process method, naturally rich in glycerin adding to super moisturising and emollient qualities.'
            }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: 'the little garden soapery | follow on facebook ',
      description: 'Hand crafted soaps made with olive oil, coconut oil and shea butter. No palm oil, SLS or parabens here! Made in small batches using the cold process method, naturally rich in glycerin adding to super moisturising and emollient qualities.'
            }
  },
  {
    path: '/shop',
    name: 'shop',
    component: StoreView,
    meta: {
      title: 'the little garden soapery | shop online with Etsy ',
      description: 'Hand crafted soaps made with olive oil, coconut oil and shea butter. No palm oil, SLS or parabens here! Made in small batches using the cold process method, naturally rich in glycerin adding to super moisturising and emollient qualities.'
            }
  },
  {
    path: '/blog',
    name: 'blog',
    component: AboutView,
    meta: {
      title: 'the little garden soapery | handmade soaps and thoughtful gifts. ',
      description: 'Hand crafted soaps made with olive oil, coconut oil and shea butter. No palm oil, SLS or parabens here! Made in small batches using the cold process method, naturally rich in glycerin adding to super moisturising and emollient qualities.'
            }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'the little garden soapery | get in touch. ',
      description: 'Hand crafted soaps made with olive oil, coconut oil and shea butter. No palm oil, SLS or parabens here! Made in small batches using the cold process method, naturally rich in glycerin adding to super moisturising and emollient qualities.'
            }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = to.meta.title;
  var metaDesc =  document.createElement('meta');  
  metaDesc.setAttribute('property', 'og:description');  
  metaDesc.content = to.meta.description;  
  document.getElementsByTagName('head')[0].appendChild(metaDesc);
  next();
});

export default router
