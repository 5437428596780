import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp }  from 'firebase/app'
import { getAnalytics } from "firebase/analytics"


const firebaseConfig = {
  apiKey: "AIzaSyAQ3iqkkCjvLI8SMBKnvnsNT-0HFIWSu-M",
  authDomain: "little-garden-soapery.firebaseapp.com",
  projectId: "little-garden-soapery",
  storageBucket: "little-garden-soapery.appspot.com",
  messagingSenderId: "459463038408",
  appId: "1:459463038408:web:9da52b37dafec5e8c39cfd",
  measurementId: "G-4D2L28XSR9"
};

const app = initializeApp(firebaseConfig)

createApp(App).use(router).mount('#app')

const analytics = getAnalytics(app);