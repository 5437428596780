<template>
  <div class="page">
    <h2>What's new..</h2>
    <a class="bluelink" href="https://www.facebook.com/50AP5"><h3>For the latest news & events, follow <span class="greenlink">the little garden soapery</span> on facebook.</h3></a>

    <a href="https://www.facebook.com/50AP5"><img class="corp-logo" src="../assets/fb_logo.png" alt="follow the little garden soapery on facebook"/></a>
  </div>
</template>
<style >

</style>