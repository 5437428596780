<template>
<div class="banner">
  <!-- <h1>the little garden soapery</h1> -->
    <div id="logo" itemscope itemtype="http://schema.org/Organization">
      <a itemprop="url" href="http://www.thelittlegardensoapery.co.uk/">
      <div v-if="bigLogo" class="fullBanner" >
        <img itemprop="logo" src="./assets/LGS_Logo.png"
          alt="the little garden soapery Logo"/>
      </div>
        <div v-else class="smallBanner" >
          <h1>the little garden soapery</h1>
        </div>
      </a>
    </div>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link :to="{ name: 'about' }">News & Events</router-link> |
    <router-link :to="{ name: 'shop' }">Shop</router-link> |
    <router-link :to="{ name: 'blog' }">Soap Box Blog</router-link> |
    <router-link :to="{ name: 'contact' }">Contact</router-link>
  </nav>
  <hr>
      <h4>handmade soaps and thoughtful gifts.</h4>
  <hr>
</div>
    <router-view/>


</template>

<script>

export default {
  data() {
    return {
      bigLogo: true,
    }
    
  },
   created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      console.log(window.scrollY);
      if (window.scrollY > 150) {
          console.log(this.bigLogo)
        return (this.bigLogo = false);

      }
      if (window.scrollY <= 150) {
        if (!this.bigLogo) {
          console.log(this.bigLogo)
          return (this.bigLogo = true);

        }
      }
    },
  },
}
</script>


<style>
/* special-elite-regular - latin */
.smallBanner {

  white-space: nowrap;

}
.smallBanner h1{
  font-family: 'Special Elite';
  font-style: normal;
  font-weight: 200;
  font-size: 2vw;
  color: #1A683D;
  margin: 1em 1em auto;
}
@media (max-width: 1800px) {
.smallBanner h1{
  font-size: 3vw;
  }
  }
  @media (max-width: 1200px) {
.smallBanner h1{
  font-size: 4vw;
  }
  }
@media (max-width: 800px) {
.smallBanner h1{
  font-size: 6vw;
  }
}
a {
    text-decoration: none;
}
body {
  background-image: url("./assets/paper-background.png");
  margin: 1em;
  /* padding: 1em; */
}
body, .banner{
  background-color: white;
  background-image: url("./assets/paper-background.png");
}
#app {
  font-family: orator-std, monospace;
  font-style: normal;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1A683D;
  /* display: flex;
  flex-direction: column; */

}
.page {

  padding-top: 28em;

}
h4 {
  color: #6C95A2;
}
.banner {
  padding-top: 1em;
  width: 100%;
  top: 0;
  left: 0px;
  position: fixed;

}
#logo{
  width:  100%;;
}

#logo img{
max-width: 100%;
max-height: 300px;
    -webkit-filter: drop-shadow(1px 3px 1px rgba(52, 52, 52, 0.219));
    filter: drop-shadow(1px 3px 1px rgba(65, 64, 64, 0.183));

}
nav {
  padding: 25px 30px 20px;
}

nav a {

  color: #1A683D;
}

nav a.router-link-exact-active {
  color: #57361B;
}
.bluelink {
  color: #6C95A2;
}
.greenlink {
  color: #1A683D;
  white-space: nowrap;
}
.corp-logo {
  max-width: 150px;
}
</style>
