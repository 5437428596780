<template>
<div class="page">
  <div class="gallery">

    <img src="../assets/Artisan-Soaps.jpg" alt="Handmade Artisan Soaps"/>
    <img src="../assets/LGS-Creams.jpg" alt="Palm Oil Free moisturisers"/>
    <img src="../assets/SoapDishBanner.jpg" alt="Soap made with 100% Natural Ingredients"/>
  </div>
</div>
</template>

<script>
import HomeSlide from '@/components/HomeSlide.vue'
export default {
  components: { HomeSlide },
}
</script>

<style>
.gallery {
  /* display: flex;
  flex-direction: column; */

}
.gallery img{
  /* max-height: 100%; */
  max-width: 100%;
  padding: 2px;
}
@media (max-width: 800px) {
  .gallery {
    flex-direction: column;
  }
}
</style>
