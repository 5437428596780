<template>
  <div class="page">
    <h2 v-if="show">Get in touch..</h2>
    <h3 v-if="!show">Message Sent</h3>
  <form v-if="show" class="contact-form" @submit.prevent="sendEmail">

    <input 
      type="text" 
      name="first_name"
      v-model="first_name" 
      placeholder="First Name" 
      required>
    <input 
      type="text"
      name="last_name"
      v-model="last_name" 
      placeholder="Last Name" 
      required>
    <input 
      type="email" 
      name="email"
      v-model="email" 
      placeholder="Email" 
      required>
    <input 
      type="number" 
      name="phone"
      v-model="phone" 
      placeholder="Phone" 
      required>
    <textarea 
      rows="6"
      name="message" 
      v-model="message" 
      placeholder="Type your message here..." 
      required>
    </textarea>
  <button type="submit">Send</button>
  </form>
  </div>
</template>
<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactUs',
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      show: true
    }
  },
  methods: {
    sendEmail(e) {
      console.log(e)
      try {
        emailjs.sendForm('service_0bbkx9q', 'template_cr80wcv', e.target,
        'eZwkgJ4uB0RTpNYFY', {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.phone =''
      this.message = ''
      this.show = false
    },
  }
}
</script>


<style>
.contact-form {
  margin: 30px auto;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.contact-form input, textarea{
  margin:1em;
  padding: 2em;
  border-radius: 7px;
  border-width: 1px;

}
button {

  background: #6C95A2;
  border: 0;
  margin: 1em;
  padding: 1em;
  color: white;
  font-weight: bold;
  border-radius: 7px;
  border-width: 1px;

}
button:hover {
  background:  #1A683D;
  cursor: pointer;
}
textarea {
  height: auto;
}
</style>
